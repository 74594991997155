import React, { useState } from 'react';
import { Link } from 'gatsby';
import useWindowSize from "../hooks/useWindowSize";
import MobileNav from "./mobilenav";
import PropTypes from "prop-types";

const MiddleNav = (props) =>  {
  const [className, setClassName] = useState('dropdown-link')
  const {width} = useWindowSize();

  const breakpoint = props.site === 'BMW' ? 1376 : 1200
  const isMobile = width < breakpoint;

  const mouseEnter = () => {
    setClassName('dropdown-link active')
  };
  const mouseLeave = () => {
    setClassName('dropdown-link')
  };

  if (isMobile) {
    return <MobileNav isOpen={props.isOpen}/>;
  }

    return (
      <>
         <ul>
            <li className="middle">
              <ul>
              <li className="dropdown">
                <button className={className}>Classes</button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="/teenschool"
                      activeClassName="active"
                      className="dropdown-nav"
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                      Teen School
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/driverschool"
                      activeClassName="active"
                      className="dropdown-nav"
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                      Driver's School
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link
                      to="/mschool"
                     activeClassName="active"
                      className="dropdown-nav inner-dropdown-nav"
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                     M School
                    </Link>
                    <ul className="dropdown-menu inner-dropdown-menu">
                      <li>
                        <Link
                          to="/mschool/mdriver"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          M Driver's Package
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mschool/onedaymschool"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          One-Day M School
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mschool/twodaymschool"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          Two-Day M School
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mschool/advancedmschool"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          Advanced M School
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mschool/m4gt4"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          M4 GT4 School
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mschool/racelicenseschool"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={mouseEnter}
                          onMouseLeave={mouseLeave}
                        >
                          Race License School
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown"  style={{width: "130%"}}>
                <button className="dropdown">M Track Series</button>
                <ul className="dropdown-menu">
                  <li className="dropdown">
                    <button className="dropdown-nav">
                      Indianapolis
                    </button>
                    <ul className="dropdown-menu inner-dropdown-menu">
                    <li>
                        <Link
                          to="/indy-halfday"
                          activeClassName="active"
                          className="dropdown-nav"
                        >
                          Half-Day M Experience
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/indy-fullday"
                          activeClassName="active"
                          className="dropdown-nav"
                        >
                          Full-Day M Intensive
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/experiences" activeClassName="active" className="dropdown-nav">
                  Experiences
                </Link>
              </li>
              <li>
                <Link to="/groupevents" activeClassName="active">
                  Group Events
                </Link>
              </li>
              <li>
                <Link to="/delivery" activeClassName="active">
                  Delivery
                </Link>
              </li>
              <li>
                <Link to="/locations" activeClassName="active">
                  Locations
                </Link>
              </li>
              </ul>
            </li>
            <li>
              <a className="nav-btn" href="https://pds.eventsbmw.com/" aria-label="Book Now"  target="_blank" rel="noopener noreferrer">
                Book Now
              </a>
            </li>
          </ul>
      </>
    )
}

MiddleNav.defaultProps = {
  isOpen: false,
  site:""
};

MiddleNav.propTypes = {
  isOpen: PropTypes.bool,
  site: PropTypes.string
};

export default MiddleNav;

